import React from 'react';
// import Image from 'next/image';
import { AboutUs, PopularPrograms, Courses, Contacts, Socials } from '../../Constants/FooterConstants';
import '../../fonts.css'
import './styles/Footer.css'; // Import the CSS file

function Footer() {
    return (
        <div className="footer-container">
            <div className="footer-wrapper">
                <div>
                    <h3 className="footer-heading">About us</h3>
                    <div className="footer-links-container">
                        {AboutUs.map((about) => (
                            <a key={about.id} href={about.aboutLink} target='_blank'>
                                <p className="footer-link">{about.aboutTxt}</p>
                            </a>
                        ))}
                    </div>
                </div>
                <div className="footer-popular-programs">
                    <h3 className="footer-heading">Popular Programs</h3>
                    <div className="footer-programs-container">
                        {PopularPrograms.map((program) => (
                            <a key={program.id} href={program.programLink} target='_blank' >
                                <div className="footer-program-item">
                                    <div className="footer-program-image">
                                        <img src={program.programImg} alt="program-img"></img>
                                    </div>
                                    <div className="footer-program-description">
                                        <h3 className="footer-program-name">{program.programName}</h3>
                                    </div>
                                </div>
                            </a>
                        ))}
                    </div>
                </div>
                <div>
                    <h3 className="footer-heading">More Courses</h3>
                    <div className="footer-links-container">
                        {Courses.map((course) => (
                            <a key={course.id} href={course.courseLink} target='_blank'>
                                <p className="footer-link">{course.courseName}</p>
                            </a>
                        ))}
                    </div>
                </div>
                <div className='footer-last-div'>
                    <div className='footer-contact-section'>
                        <h3 className="footer-heading">Contact Us</h3>
                        <div className="footer-contacts-container">
                            {Contacts.map((contact) => (
                                <div key={contact.id} className="footer-contact-item">
                                    <div className={`footer-contact-icon ${contact.id === 2 ? 'wide-icon' : ''}`}>
                                        <img src={contact.contactIcon} width={24} alt="contact-icon" ></img>
                                    </div>
                                    <a href={contact.contactLink} target='_blank'>
                                        <h3 className="footer-contact-text">{contact.contactTxt}</h3>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="footer-social-links">
                        <h3 className="footer-heading">Social Links</h3>
                        <div className="footer-social-icons">
                            {Socials.map((social) => (
                                <div key={social.id} className="footer-social-item">
                                    <a href={social.socialLink} target='_blank'>
                                        <img src={social.socialIcon} alt="social-icon"></img>
                                    </a>
                                </div>
                            ))}
                        </div>
                        <div className="footer-reserved-text">
                            <h3>All Right Reserved with @Programming Pathshala</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
