import axios from "axios";
import {
    nullCheckExistingParams,
    getUserAdDetails,
} from "../../Utilities/CommonUtility/CommonUtility.js";
import { sendEventToGTM } from "../../Utilities/GoogleAnalytics/gtmManager.js";

const HEADERS = {
    "Cache-control": "no-store",
};
const applyCouponCode = (
    couponCode,
    event_id,
    mrp,
    setDiscountPercent,
    setDiscountedPrice,
    setCouponCodeInvalid,
    setLoader
) => {
    axios
        .post(`${process.env.REACT_APP_MISC_URL}/applyCouponCode`, {
            referral_code: couponCode,
            event_id: event_id,
        })
        .then((response) => {
            setLoader(false);
            if (response.status === 200) {
                if (response.data && response.data.discount_percent) {
                    setCouponCodeInvalid(false);
                    let discount_percent = response.data.discount_percent;
                    setDiscountPercent(discount_percent);
                    setDiscountedPrice(Math.floor(mrp - (mrp * discount_percent) / 100));
                }
            }
        })
        .catch((error) => {
            setLoader(false);
            setCouponCodeInvalid(true);
            setDiscountPercent(null);
            setDiscountedPrice(null);
        });
};

const postEventParticipantDetails = (
    data,
    setLoader,
    setUserAlreadyRegistered,
    setRegistrationSuccess,
    setFormSubmitError
) => {
    axios
        .post(`${process.env.REACT_APP_MISC_URL}/postEventParticipantDetails`, {
            name: data.name,
            email: data.email,
            phone_number: data.phone_number,
            organisation: data.organisation,
            event_id: data.event_id,
            affiliate_id: data.affiliate_id,
        })
        .then((response) => {
            if (response.status === 200) {
                if (!nullCheckExistingParams()) {
                    axios.post(
                        process.env.REACT_APP_AUTH_URL + "/eventsUtmDetails",
                        {
                            email: data.email,
                            event_id: data.event_id,
                            ad_details: getUserAdDetails(),
                        },
                        { headers: HEADERS }
                    );
                } else {
                    localStorage.removeItem("utmParameters");
                }
                localStorage.removeItem("affiliate_id");
                setLoader(false);
                setRegistrationSuccess(true);
                setUserAlreadyRegistered(false);
            }
        })
        .catch((error) => {
            setLoader(false);
            setRegistrationSuccess(false);
            if (error.response.status === 403) {
                setUserAlreadyRegistered(true);
            }
            if (error.response.status === 400) {
                setFormSubmitError("Invalid input details");
            } else {
                setFormSubmitError("Something went wrong, pls try again");
            }
        });
};

const initiateEventPayment = (
    data,
    setPaymentDetails,
    placeOrderAndShowRz,
    setPaymentsId,
    email,
    setLoader,
    redirectToReferYourFriendsPage,
    setFormSubmitError,
    setReferralCode,
    setRegisterFormModalOpen
) => {
    sendEventToGTM({ event: "clicked-on-pay-now" });
    axios
        .post(process.env.REACT_APP_MISC_URL + "/initiateEventPayment", data)
        .then((response) => {
            setLoader(false);
            if (response.status === 200) {
                setRegisterFormModalOpen(false);
                if (!nullCheckExistingParams()) {
                    axios.post(
                        process.env.REACT_APP_AUTH_URL + "/eventsUtmDetails",
                        {
                            email: data.email,
                            event_id: data.event_id,
                            ad_details: getUserAdDetails(),
                        },
                        { headers: HEADERS }
                    );
                } else {
                    localStorage.removeItem("utmParameters");
                }

                let apiRes = response.data.Response;
                let { orderId, key, amount, currency, referralCode } = apiRes;

                setReferralCode(referralCode);
                setPaymentDetails(orderId, key, amount, currency, "");
                placeOrderAndShowRz(email, orderId, key, amount, currency, setPaymentsId);
            }
        })
        .catch((error) => {
            setLoader(false);
            if (error.response.status === 401) {
                setFormSubmitError(error.response.data);
                return;
            }
            setFormSubmitError("Internal Server Error");
        });
};

const placeOrderAndShowRz = (email, orderId, key, amount, currency, setPaymentsId) => {
    try {
        let name = email.split("@")[0];
        var options = {
            key: `${key}`,
            amount: `${amount}`.toString(),
            currency: `${currency}`,
            name: "Programming Pathshala",
            description: "Events Payment",
            order_id: `${orderId}`,
            handler: (response) => {
                setPaymentsId(response.razorpay_payment_id);
            },
            prefill: {
                name,
                email,
                contact: "",
            },
            notes: {
                address: "",
            },
            theme: {
                color: "#444BAB",
            },
            config: {
                display: { hide: [{ method: "upi", flows: ["qr"] }] },
            },
        };

        const rzp1 = new window.Razorpay(options);
        rzp1.open();
    } catch (e) {}
};

const getDefaultDiscountPercentage = (default_coupon_code, event_id, setDefaultDiscountPercent) => {
    axios
        .post(`${process.env.REACT_APP_MISC_URL}/getDefaultDiscountPercentage`, {
            default_coupon_code: default_coupon_code,
            event_id: event_id,
        })
        .then((response) => {
            if (response.status === 200 && response.data && response.data.discount_percent) {
                setDefaultDiscountPercent(response.data.discount_percent);
            }
        })
        .catch((error) => {
            console.log("internal server error");
        });
};

const postConsultationEventLeads = (
    name,
    phone,
    source,
    source_id,
    setLoading,
    setValidationError
) => {
    let data = {
        name: name,
        phone: phone,
        source: source,
        source_id: source_id,
    };
    axios
        .post(`${process.env.REACT_APP_MISC_URL}/postEventLeads`, data)
        .then((response) => {
            setLoading(false);
        })
        .catch((error) => {
            setLoading(false);
            setValidationError("Something Went Wrong.Please Try again");
        });
};

export {
    initiateEventPayment,
    placeOrderAndShowRz,
    postEventParticipantDetails,
    applyCouponCode,
    getDefaultDiscountPercentage,
    postConsultationEventLeads,
};
