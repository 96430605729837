import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import DehazeIcon from "@mui/icons-material/Dehaze";
import logo from "./../../Resources/Images/PpaLogo.svg";
import { useGlobalContext } from "../../Contexts/GlobalContext.js";
import closeButton from "./../../Resources/Images/close.svg";
import "./styles/Header.css";
import { GTM_TRACKER } from "../../Utilities/GoogleAnalytics/gtmConstants.js";
import HeaderCoursesDropDown from "../HeaderCoursesDropDown/HeaderCoursesDropDown.js";
import RenaissanceHoverBox from "../Renaissance/RenaissanceHoverBox.js"
import CertificationHoverBox from "../CourseCertification/CertificationHoverBox.js"
import FreeLearningHoverBox from "../HeaderResources/FreeLearningHoverBox.js"

export default function TemporaryDrawer() {
    const { loggedIn, subscribed, everSubscribed, initiateLogout, freeTrialFormFilled } =
        useGlobalContext();

    const [state, setState] = React.useState({
        left: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const getSignupButton = () => {
        return (
            <button
                className="action-btn"
                onClick={() => {
                    window.open(`${process.env.REACT_APP_FRONTEND_MAIN_URL}signup`);
                }}
            >
                Start Free trial
            </button>
        );
    };

    const getFreeTrailOrDashboardButton = () => {
        if (!loggedIn) return getSignupButton();
    };

    const getButtons = () => {
        return (
            <>
                {loggedIn ? (
                    <>
                    <button
                        className="side-navigation-login-btn"
                        onClick={() => {
                            initiateLogout();
                        }}
                    >
                        <span className="button-text">Log Out</span>
                    </button>
                    <button className="sing-up-btn" onClick={()=>{window.open(`${process.env.REACT_APP_FRONTEND_MAIN_URL}course-select`,"_self")}}>Start Learning</button>
                    </>
                ) : (
                    <button
                        onClick={() => {
                            window.open(`${process.env.REACT_APP_FRONTEND_MAIN_URL}login`);
                        }}
                        className={`${GTM_TRACKER.LOGIN} side-navigation-login-btn`}
                    >
                        Log In
                    </button>
                )}
                {getFreeTrailOrDashboardButton()}
            </>
        );
    };

    const NavigationList = (anchor) => (
        <Box
            sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 330 }}
            role="presentation"
            onClick={toggleDrawer(anchor, true)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <div className="side-nagivation-list-mob">
                <HeaderCoursesDropDown isMobileView={true}/>
                <RenaissanceHoverBox isMobileView={true}/>
                <CertificationHoverBox isMobileView={true}/>
                <FreeLearningHoverBox isMobileView={true}/>
            </div>
            <div className="side-nagivation-login-trail-btn">{getButtons()}</div>
        </Box>
    );

    return (
        <div>
            {["left"].map((anchor, index) => (
                <React.Fragment key={index}>
                    <Button onClick={toggleDrawer(anchor, true)}>
                        <DehazeIcon sx={{ color: "#333" }} />
                    </Button>
                    <Drawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                    >
                        <div className="drawer-wrapper">
                            <div className="side-navigation-header-wrapper">
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://programmingpathshala.com/"
                                >
                                    <img className="ppa-logo-mob" src={logo} alt="PPA-logo" />
                                </a>
                                <div onClick={toggleDrawer(anchor, false)}>
                                    <img src={closeButton} height={30} alt="exit" className="close-button-img" />
                                </div>
                            </div>
                            {NavigationList(anchor)}
                        </div>
                    </Drawer>
                </React.Fragment>
            ))}
        </div>
    );
}
