import TagManager from "react-gtm-module";

const initializeGTM = () => {
    TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_ID });
    // To remove or PAUSE after verification
};

// https://developers.google.com/tag-platform/tag-manager/datalayer
const sendEventToGTM = (data) => {
    window.dataLayer.push(data);
    // To remove or PAUSE after verification
};

export { initializeGTM, sendEventToGTM };
