import React from "react";
import NavLinks from "./NavLinks";
import logo from "./../../Resources/Images/PpaLogo.svg";
import HeaderForMob from "./HeaderForMob";
import { useGlobalContext } from "../../Contexts/GlobalContext";
import useWindowDimensions from "../../Hooks/UseWindowDimensions";
import "./styles/Header.css";
import { GTM_TRACKER } from "../../Utilities/GoogleAnalytics/gtmConstants";
import { useLocation } from "react-router-dom";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const Header = (props) => {
    const query = useQuery();
    const { loggedIn, initiateLogout } = useGlobalContext();
    const { windowWidth } = useWindowDimensions();

    const getHeaderforDesktop = () => {
        return (
            <div className="header-links">
                <div className="ppa-logo-container">
                    <a target="_blank" rel="noreferrer" href="https://programmingpathshala.com/">
                        <img className="ppa-header-logo" src={logo} alt="" />
                    </a>
                </div>

                <NavLinks />

                <div>{getHeaderBtnForDeskTop()}</div>
            </div>
        );
    };
    const getHeaderBtnForDeskTop = () => {
        if (!loggedIn) {
            return (
                <div className="header-buttons">
                    <div
                        className={GTM_TRACKER.LOGIN}
                        onClick={() => {
                            window.open(`${process.env.REACT_APP_FRONTEND_MAIN_URL}login`);
                        }}
                    >
                        <p className="log-in-or-out-btn">Log In</p>
                    </div>
                    <div
                        className={GTM_TRACKER.SIGN_UP}
                        onClick={() => {
                            window.open(`${process.env.REACT_APP_FRONTEND_MAIN_URL}signup`);
                        }}
                    >
                        <button className="sign-up-btn">Start Free Trial</button>
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    <p className="log-in-or-out-btn" onClick={() => initiateLogout()}>Log Out</p>
                    <button className="sign-up-btn" onClick={()=>{window.open(`${process.env.REACT_APP_FRONTEND_MAIN_URL}course-select`,"_self")}}>Start Learning</button>
                </div>
            );
        }
    };

    return (
        <div className=" header-container">
            {windowWidth > 600 ? getHeaderforDesktop() : <HeaderForMob />}

            {windowWidth <= 600 ? (
                <>
                    <div style={{display: "flex"}} className="ppa-header-logo-wrapper">
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://programmingpathshala.com/"
                        >
                            <img
                                className="ppa-logo"
                                src={logo}
                                alt="ppa-logo"
                            />
                        </a>
                    </div>
                    <div></div>
                </>
            ) : null}
        </div>
    );
};

export default Header;
