import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import "./fonts.css";
import EventPageRoute from "./Components/Events/EventPage/Body/EventPageRoute";
import PreviousEvents from "./Components/Events/PreviousEvents/PreviousEvents";
import "./App.css";

import { GlobalContextProvider } from "./Contexts/GlobalContext";
import EventDetailsRoute from "./Components/Events/EventDetails/Body/EventDetailsRoute";
import ReferYourFriend from "./Components/ReferYourFriend/ReferYourFriend";

function App() {  
    return (
        <div>
            {/* <TfpRibbon/> */}
            <GlobalContextProvider>
                <Router>
                    <Switch>
                        <Route exact path="/">
                            <Redirect to="/Events" />
                        </Route>
                        <Route exact path="/Events">
                            <EventPageRoute />
                        </Route>
                        <Route exact path="/Refer-your-friend">
                            <ReferYourFriend />
                        </Route>
                        <Route path="/Events/:event_id">
                            <EventDetailsRoute />
                        </Route>
                        <Route path="/Previous-Events">
                            <PreviousEvents />
                        </Route>
                    </Switch>
                </Router>
            </GlobalContextProvider>
        </div>
    );
}

export default App;
